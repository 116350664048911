/**
 * Store.
 */

import { mutationsAuth, stateAuth } from '@/store/auth.js'
import { mutationsDataState, stateDataState } from '@/store/data_state.js'
import { mutationsError, stateError } from '@/store/error.js'
import { mutationsReport, stateReport } from '@/store/report.js'
import { mutationsSetting, stateSetting } from '@/store/setting.js'
import { mutationsUser, stateUser } from '@/store/user.js'

import Vue from "vue"

export const mutations = {
    ...mutationsAuth,
    ...mutationsDataState,
    ...mutationsError,
    ...mutationsReport,
    ...mutationsSetting,
    ...mutationsUser
}

export const state = Vue.observable({
    ...stateAuth,
    ...stateDataState,
    ...stateError,
    ...stateReport,
    ...stateSetting,
    ...stateUser
})