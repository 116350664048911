export default [
    {
        name: 'report',
        path: '/report',
        component: ( ) => import('@/views/report/Report.vue')/*,
        meta: {
            redirect_if_logged_in: true
        }*/
    },

    {
        name: 'reports',
        path: '/reports',
        component: ( ) => import('@/views/report/Reports.vue')/*,
        meta: {
            redirect_if_logged_in: true
        }*/
    }
]