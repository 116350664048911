export default [
    {
        name: 'anonymous-content',
        path: '/anonymous-content',
        component: ( ) => import('@/views/gms/AnonymousContent.vue'),
        /*meta: {
            subject: 'Auth',
            action: 'readYourself',
            redirect_if_logged_in: true
        }*/
    },

    {
        name: 'user-my-account',
        path: '/my-account',
        component: ( ) => import('@/views/gms/MyAccount.vue'),
        meta: {
            subject: 'User',
            action: 'readYourself'
        }
    },

    {
        name: 'users',
        path: '/users',
        component: ( ) => import('@/views/gms/Users.vue'),
        meta: {
            subject: 'User',
            action: 'read'
        }
    }
]