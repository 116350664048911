/**
 * This file is part of the GMS package.
 * 
 * @version 1.4
 */

const axios = require('axios')
import { createFatalError, processErrors } from './error'
import storage from './storage.js'
var dot = require('dot-object');

/**
 * Sends a request to the server.
 * 
 * NOTICE 
 *    It only supports GET and POST methods!
 *    Uses Axios.
 * 
 * SEE https://github.com/axios/axios
 * 
 * @param   {string}  route
 * @param   {*}       data
 * @param   {*}       options
 *    {
 *      method: get | GET | post | POST
 *      ... other Axios options
 *    }
 * 
 * @return  {Promise}
 */
 export function sendRequest (route, data, options = { }) {
    //console.log("options", options)

    /**************************************************
     * Options
     */
    let methods = ["get", "post"]
    let method = (options.method ?? "post").toLowerCase( )

    if (!methods.includes(method)) {
      createFatalError(`Invalid option "method". sendRequest("${route}", ${JSON.stringify(data)}, ${JSON.stringify(options)})`)
    }

    /**************************************************
     * HTTP hlavičky
     */
    /*let headers = {
      //'Content-Type': 'multipart/form-data' // TODO Případně odkomentovat
      //'Content-Type': 'application/x-www-form-urlencoded'
    }*/

    let headers = options["headers"] ?? { };
  
    /**
     * Accept-Language
     * Vrátí locale ze storage, pokud je nastaveno
     */
    let g11n = storage.getItem("g11n")

    if (g11n != null) {
      headers["Accept-Language"] = g11n.locale
    }

    /**
     * Authorization
     */
    const auth = storage.getItem("auth")
    const token = dot.pick("access_token", auth)
    
    if (token) {
      //axios.defaults.headers.common['Authorization'] = token
      headers["Authorization"] = token
    }

    //console.log("headers", headers)

    /**************************************************
     * Axios default config
     */
    let axiosDefaultConfig = {
      method: "post",
      headers: headers,
      timeout: 10000
    }

    /**************************************************
     * Axios config
     */
    const axiosConfig = Object.assign(axiosDefaultConfig, options);

    /**
     * Http metoda POST
     */
    if (axiosConfig.method == "post" || axiosConfig.method == "POST") {
      return new Promise((resolve, reject) => {
        axios.post(
          route,
          data,
          axiosConfig)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          //console.log('api.js#sendRequest( ).catch,', error)
          //console.log('sendRequest.catch', error.code)
          //console.log('sendRequest.catch#error.message', error.message)
          //console.log('sendRequest.catch#error.response', error.response)
          //console.log('sendRequest.catch', error.response.data)
    
          let errors = [ ]
    
          if (error.response === undefined) {
            // Request timeout, atd.
            //errors = [ buildFatalError(error.message) ]
            errors = [ error.message ]
          } else {
            errors = error.response.data.errors
          }
    
          processErrors(errors)
          reject(errors)
        })
      })
    } else {
      /**
       * Http metoda GET
       */
      return new Promise((resolve, reject) => {
        axios.get(
          route,
          axiosConfig)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          let errors = [ ]
    
          if (error.response === undefined) {
            // Request timeout, atd.
            //errors = [ buildFatalError(error.message) ]
            errors = [ error.message ]
          } else {
            errors = error.response.data.errors
          }
    
          processErrors(errors)
          reject(errors)
        })
      })
    }
}