/**
 * Konstanty ve frontend aplikaci.
 */

/**
 * Stav dat.
 */
export const DATA_STATE_NOT_READY   = 'NOT_READY'
export const DATA_STATE_PREPARING   = 'PREPARING'
export const DATA_STATE_READY       = 'READY'
