<template>
    <div>
        <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-light">
            <!--<b-navbar-brand
                :to="{ name: default_view_for_user, params: { }}"
            >
                GMS package
            </b-navbar-brand>-->
            <b-navbar-brand
                :XXXto="{ name: 'default-view', params: { }}"
            >
                SQL Reporter
            </b-navbar-brand>

            <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navb">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navb">
                <ul class="navbar-nav mr-auto">
                    <!--<li
                        class="nav-item"
                        v-if="$can('read', 'User')"
                    >
                        <router-link
                            class="nav-link"
                            :to="{ name: 'default-view-logged-in-user', params: { }}"
                        >
                            Home
                        </router-link>
                    </li>-->

                    <!--<li
                        class="nav-item"
                    >
                        <router-link
                            class="nav-link"
                            :to="{ name: 'anonymous-content', params: { }}"
                        >
                            {{ $t('Anonymní obsah') }}
                        </router-link>
                    </li>-->

                    <li
                        class="nav-item"
                        v-if="$can('read', 'Report')"
                    >
                        <router-link
                            class="nav-link"
                            :to="{ name: 'reports', params: { }}"
                        >
                            Reporty
                        </router-link>
                    </li>

                    <li
                        class="nav-item"
                        v-if="$can('readYourself', 'User')"
                    >
                        <router-link
                            class="nav-link"
                            :to="{ name: 'user-my-account', params: { }}"
                        >
                            {{ $t('Můj účet') }}
                        </router-link>
                    </li>

                    <li
                        class="nav-item"
                        v-if="$can('read', 'User')"
                    >
                        <router-link
                            class="nav-link"
                            :to="{ name: 'users', params: { }}"
                        >
                            {{ $t('Uživatelé') }}
                        </router-link>
                    </li>

                                                                <!--<li class="nav-item">
                                                                    <a class="nav-link" href="javascript:void(0)">Link</a>
                                                                </li>
                                                                    <li class="nav-item">
                                                                    <a class="nav-link disabled" href="javascript:void(0)">Disabled</a>
                                                                </li>-->
                </ul>
                <form class="form-inline my-2 my-lg-0">
                                                                <!--<input class="form-control mr-sm-2" type="text" placeholder="Search">
                                                                <button class="btn btn-success my-2 my-sm-0" type="button">Search</button>-->


                    <span class="ml-3 small text-muted">
                        ({{ config.application.build }})
                    </span>

                    <span class="ml-3">
                        Databáze: ({{ database_id }})
                    </span>

                    <span class="ml-3">
                        {{ auth_login_user.full_name }}
                    </span>

                    <!--<b-button
                        size="sm"
                        variant="success"
                        class="ml-3 mr-3"
                        v-show="!$can('logout', 'Auth')"
                        :to="{ name: 'auth-login', params: { }}"
                    >
                        {{ $t('Přihlásit') }}
                    </b-button>-->

                    <b-button
                        size="sm"
                        variant="success"
                        class="ml-3 mr-3"
                        v-show="$can('logout', 'Auth')"
                        @click="logout"
                    >
                        {{ $t('Odhlásit') }}
                    </b-button>

                    <!--<G11n />-->
                </form>
            </div>
        </nav>

    </div>
</template>

<script>
/**
 * EXAMPLE https://www.w3schools.com/bootstrap4/bootstrap_navbar.asp
 */

import { state, mutations } from '@/store/index.js'
//import storage from "@/lib/gms/storage"
//import { createNotice } from '@/lib/gms/error.js'
//import G11n from './gms/G11n.vue'
//import { getDefaultViewForUser } from '@/router'

export default {
    components: {
        //G11n
    },

    computed: {
        auth_access_token( ) {
            return state.auth.access_token != null ? true : false 
        },

        auth_login_user( ) {
            return state.auth.login_user ?? { full_name: "" }
        },

        config( ) {
            return state.config
        },

        database_id( ) {
            return state.database_id
        },

        default_view_for_user( ) {
            return mutations.getDefaultViewForUser( )
        },
    },

    data( ) {
        return { }
    },

    methods: {
        logout( ) {
            mutations.logoutFromReports( )
                .then(/*response*/ ( ) => {
                    //createNotice(this.$t("Byl jste úspěšně odhlášen."))

                    this.$router.push({ name: "default-view", query: { db: state.database_id  } })
                })
        }
    },

    mounted( ) { }
}
</script>

<style>
</style>