export default [
    {
        name: 'auth-login',
        path: '/auth/login',
        component: ( ) => import('@/views/auth/LoginForm.vue'),
        /*meta: {
            subject: 'Auth',
            action: 'read'
        }*/
    },

    {
        name: 'auth-not-authorized',
        path: '/auth/not-authorized',
        component: ( ) => import('@/views/auth/NotAuthorizedView.vue')
    }
]