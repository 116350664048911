import * as constants from '@/store/constants.js'
import { mutations, state } from '@/store'
import { sendRequest } from '@/lib/gms/api.js'

export const defaultsSetting = {
    config: {
        application: {
            build: null,
            title: null
        }
    }
}

export const stateSetting = { 
    config: defaultsSetting.config
}

export const mutationsSetting = {
    readSetting( ) {
        mutations.setDataStateConfig(constants.DATA_STATE_PREPARING)

        sendRequest(
            '/api/read-setting',
            { }
        )
        .then(response => {
            //console.log(response)
    
            state.config = response.data
            
            // Nastaví document.title
            document.title = state.config.application.title

            mutations.setDataStateConfig(constants.DATA_STATE_READY)
        })
        .catch(/*errors*/ ( ) => {
            //console.log('readSetting.errors', errors)

            mutations.setDataStateConfig(constants.DATA_STATE_NOT_READY)
        })
    }
}