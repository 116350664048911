<template>
  <div id="app" class="container" classFULL_WIDTH="container-fluid">
    <div id="nav"></div>
    <GMS/>
    <router-view/>
    <main-menu />
  </div>
</template>

<script>
import GMS from './components/gms/GMS.vue'
import MainMenu from './components/MainMenu.vue'

export default {
  components: {
    GMS,
    MainMenu
  },
  
  mounted( ) {
    // Import external scripts
    // NOTICE Toto funguje i na lokálním serveru (vue serve)
      // Awesome fonts
    let externalScriptAwesome = document.createElement('script')
    externalScriptAwesome.setAttribute('src', 'https://kit.fontawesome.com/4380d4fa1f.js')
    document.head.appendChild(externalScriptAwesome)
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
