const axios = require("axios")
//import * as constants from "@/store/constants.js"
import { mutations, state } from "@/store"
import { sendRequest } from "@/lib/gms/api"
import storage from "@/lib/gms/storage"
import { deepCopy } from "@/lib/gms/utils"
import ability from "@/lib/acl/ability"

export const defaultsAuth = {
    /**
     * Výchozí hodnota pro CASL ability.
     */
     ability: [ ],

    /**
     * Výchozí hodnota pro přístupový token.
     */
    access_token: null,

    /**
     * Výchozí struktura pro přihlašovací formulář.
     */
    login_form: {
        uid: null,        // "admin@gms.dev",
        password: null    // "12345"
    },

    /**
     * Výchozí struktura pro přihlašovací formulář.
     */
    login_form_errors: {
        username: null,
        message: null,
        password: null
    },

    /**
     * Výchozí struktura pro přihlášeného uživatele.
     */
    login_user: {
        full_name: null
    },

    /**
     * Příznak, zda se jedná o přesměrování po úspěšném přihlášení.
     */
    is_redirect_from_login: false
}

export const stateAuth = {
    /**
     * Data Auth.
     * 
     *  @var {object}
     */
    auth: {
        /**
         * CASL ability.
         * 
         *  @var {array}    [ [ subject: ..., action: ... ], ... ]
         */
        ability: defaultsAuth.ability,

        /**
         * Bearer token.
         * 
         *  @var {string}
         */
        access_token: defaultsAuth.access_token,

        /**
         * Data přihlašovacího formuláře.
         * 
         *  @var {object}
         */
        login_form: defaultsAuth.login_form,

        /**
         * Chyby v datech přihlašovacího formuláře.
         * 
         *  @var {object}
         */
        login_form_errors: defaultsAuth.login_form_errors,

        login_user: defaultsAuth.login_user
    }
}

export const mutationsAuth = {
    /**
     * Vrací výchozí (home) routu pro daného uživatele.
     * 
     * @return {string}
     */
    getDefaultViewForUser( ) {
        console.log("getDefaultViewForUser", mutations.isUserLoggedIn( ))
    
        if (mutations.isUserLoggedIn( ) == true) {
            return "default-view-logged-in-user"
        } else {
            return "default-view-anonymous-user"
        }
    },

    /**
     * Příznak, zda je uživatel přihlášen.
     * 
     * @return  {boolean}
     */
    isUserLoggedIn( ) {
        const auth = storage.getItem("auth")

        return auth != null
            /*&& auth.login_user != null
            && auth.login_user.full_name != null*/
            && auth.access_token != null
    },


    /**
     * Provede autentizaci a autorizaci uživatele.
     * 
     * @param   {string}    username
     * @param   {string}    password
     * @return  {Promise}   
     */
    login(username, password) {
        return new Promise((resolve, reject) => {
            //mutations.setDataStateHost(constants.DATA_STATE_PREPARING)

            state.auth.login_form_errors = deepCopy(defaultsAuth.login_form_errors)

            sendRequest(
                "/api/auth/login",
                {
                    username,
                    password
                }
            )
            .then(response => {
                //mutations.setDataStateHost(constants.DATA_STATE_READY)

                const userAbility = response.data.ability
                const accessToken = response.data.access_token
                const userData = response.data.user

                storage.setItem(
                    "auth", 
                    {
                        "ability": userAbility,
                        "access_token": accessToken,
                        "login_user": userData
                    }

                )

                state.auth.ability = userAbility
                ability.update(userAbility)

                state.auth.access_token = accessToken
                axios.defaults.headers.common["Authorization"] = accessToken

                state.auth.login_user = userData

                resolve(response.data)
            })
            .catch(errors => {
                //mutations.setDataStateHost(constants.DATA_STATE_NOT_READY)

                reject(errors)
            })
        })
    },

    logout(/*params*/) {
        return new Promise((resolve/*, reject*/) => {
            storage.removeItem("auth")

            state.auth.ability = defaultsAuth.ability
            ability.update(defaultsAuth.ability)

            state.auth.access_token = defaultsAuth.access_token
            delete axios.defaults.headers.common["Authorization"]

            state.auth.login_user = deepCopy(defaultsAuth.login_user)
            state.auth.login_form = deepCopy(defaultsAuth.login_form)
            state.auth.login_form_errors = deepCopy(defaultsAuth.login_form_errors)

            resolve( )
        })
    },

    processAuthLoginErrors(errors) {
        errors.forEach(error => {
            // error.field
            if (error.field != undefined) {
                state.auth.login_form_errors[error.field] = 
                    (state.auth.login_form_errors[error.field] != null ? state.auth.login_form_errors[error.field] + "<br>" : "")
                    + error.message
            }

            // error.message
            if (error.message != undefined) {
                state.auth.login_form_errors.message = error.message;
            }
        })
    },

    /**
     * Vrátí data přihlášeného uživatele.
     * 
     * @return  {Promise}   
     */
    readLoggedInUser(/*params*/) {
        return new Promise((resolve/*, reject*/) => {
            resolve(state.auth.login_user)
        })
    },

    /**
     * Nastaví příznak, jestli došlo k přesměrování po úspěšném přihlášení.
     * 
     * @param   boolean flag
     */
    setIsRedirectFromLogin(flag) {
        state.auth.is_redirect_from_login = flag
    }
}