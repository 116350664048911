import { mutations, state } from '@/store'
import * as constants from '@/store/constants.js'
//import { testData } from '@/store/test_data.js'
import { sendRequest } from '@/lib/gms/api.js'
//import { deepCopy } from '@/lib/utils.js'

export const defaultsUser = { }

export const stateUser = {
    /**
     * Data osob.
     * 
     *  @var {object}
     * 
        [
            {
                ...
            }, ...
        ]
     */
    users_data: [ ]
}

export const mutationsUser = {
    /**
     * Načte data osob.
     * 
     * @return {Promise}
     */
    readUsers( ) {
        return new Promise((resolve, reject) => {
            mutations.setDataStateUsers(constants.DATA_STATE_PREPARING)

            sendRequest(
                'api/user/read-users',
                { }
            )
            .then(response => {
                //console.log(response)
    
                state.users_data = response.data
                
                mutations.setDataStateUsers(constants.DATA_STATE_READY)

                resolve(response.data)
            })
            .catch(errors => {
                //console.log('readPlate.errors', errors)

                mutations.setDataStateUsers(constants.DATA_STATE_NOT_READY)

                reject(errors)
            })
        })
    }
}