/**
 * This file is part of the GMS package.
 * 
 * @version 1.1
 */

/**
 *  Global EventBus.
 *  It uses the Vue.$on and Vue$.emit methods internally.
 *
 *  Event parameters:
 *  {
 *      data: ...,
 *      level: Event level,
 *      message : Message,
 *      type: Type of event
 *  }
 */

import Vue from 'vue'

export const eventBus = new Vue({ 
    created( ) {
        Object.defineProperty(this, 'eventLevels', {
            get( ) {
                return {
                    ERROR: 'ERROR',
                    INFO: 'INFO',
                    NOTICE: 'NOTICE',
                    SUCCESS: 'SUCCESS',
                    WARNING: 'WARNING'
                }
            }
        })

        Object.defineProperty(this, 'eventTypes', {
            get( ) {
                return {
                    // Error types
                    URGENT_ERROR: 'URGENT_ERROR',
                    ERROR: 'ERROR',
                    WARNING: 'WARNING',
                    NOTICE: 'NOTICE'
                }
            }
        })

        /**
         * Sets the function that will be called whenever the specified event is delivered to the listener.
         * 
         * @see     https://www.w3schools.com/jsref/met_document_addeventlistener.asp
         * @param   event_type  A String that specifies the name of the event.
         * @param   listener    Specifies the function to run when the event occurs.
         * @example addEventListener('Some event type', parameters => { ... })
         */
        this.addEventListener = this.$on

        /**
         * Dispatches an event.
         * 
         * @see     https://www.w3schools.com/jsref/met_document_addeventlistener.asp
         * @param   event_type  A String that specifies the name of the event.
         * @param   parameters  Event parameters
         * @example dispatch('Some event type', parameters = { ... })
         */
        this.dispatch = this.$emit
    }
})