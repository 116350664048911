/**
 * This file is part of the GMS package.
 * 
 * @version 1.2
 */

/**
 * Management chyb
 */

//import axios from '@axios'
import { deepFreeze } from './utils'
import { eventBus } from './event_bus'

//export const REQUIRE_ERROR = 'RequireError'           // ERROR_PRIORITY_URGENT
export const PERMISSION_ERROR = 'PermissionError'   // ERROR_PRIORITY_URGENT
export const LOGICAL_ERROR = 'LogicalError'         // ERROR_PRIORITY_URGENT
export const FATAL_ERROR = 'FatalError'             // ERROR_PRIORITY_URGENT
export const APPLICATION_ERROR = 'ApplicationError' // ERROR_PRIORITY_ERROR
export const VALIDATION_ERROR = 'ValidationError'   // ERROR_PRIORITY_ERROR
export const WARNING = 'Warning'                    // ERROR_PRIORITY_WARNING
export const NOTICE = 'Notice'                      // ERROR_PRIORITY_NOTICE

export const ERROR_PRIORITY_URGENT = 1
export const ERROR_PRIORITY_ERROR = 2
export const ERROR_PRIORITY_WARNING = 3
export const ERROR_PRIORITY_NOTICE = 4

export const ERROR_PRIORITY = deepFreeze({
  1: 'Urgent',
  2: 'Error',
  3: 'Warning',
  4: 'Notice'
})

/**
 * Obecné tělo chyby.
 * 
 * @property  {object}  [errors].[error].id       [mandatory] Id chyby
 * @property  {object}  [errors].[error].type     [mandatory] Typ chyby
 * @property  {object}  [errors].[error].priority [mandatory] Závažnost chyby
 * @property  {object}  [errors].[error].message  [mandatory] Popis chyby
 * @example   error:
 *  {
 *    type: "ApplicationError",
 *    priority: "Error",
 *    message: "Neplatné uživatelské jméno nebo heslo"
 *  }
 */
/*let errorBody = {
  type: null,
  priority: null,
  message: null
}*/

/**
 * Tělo aplikační chyby.
 */
let applicationErrorBody = {
    id: null,
    type: APPLICATION_ERROR,
    priority: ERROR_PRIORITY[ERROR_PRIORITY_ERROR],
    message: null
}

/**
 * Tělo fatální chyby.
 */
let fatalErrorBody = {
    id: null,
    type: FATAL_ERROR,
    priority: ERROR_PRIORITY[ERROR_PRIORITY_URGENT],
    message: null
}

/**
 * Tělo poznámky.
 */
let noticeBody = {
    id: null,
    type:NOTICE,
    priority: ERROR_PRIORITY[ERROR_PRIORITY_NOTICE],
    message: null
}

/**
 * Tělo varování.
 */
let warningBody = {
    id: null,
    type: WARNING,
    priority: ERROR_PRIORITY[ERROR_PRIORITY_WARNING],
    message: null
}

/**
 * Vytvoří tělo aplikační chyby.
 * 
 * @param   {string}  message [mandatory] Popis chyby
 * @return  {applicationErrorBody}
 */
export function buildApplicationError (message) {
  let error = applicationErrorBody
  error.message = message

  return error
}

/**
 * Vytvoří tělo fatální chyby.
 * 
 * @param   {string}  message [mandatory] Popis chyby
 * @return  {fatalErrorBody}
 */
export function buildFatalError (message) {
  let error = fatalErrorBody
  error.message = message

  return error
}

/**
 * Vytvoří tělo poznámky.
 * 
 * @param   {string}  message [mandatory] Text poznámky
 * @return  {noticeBody}
 */
export function buildNotice (message) {
  let notice = noticeBody
  notice.message = message

  return notice
}

/**
 * Vytvoří tělo varování.
 * 
 * @param   {string}  message [mandatory] Text varování
 * @return  {warningBody}
 */
export function buildWarning (message) {
  let warning = warningBody
  warning.message = message

  return warning
}

/**
 * Vytvoří a zpracuje aplikační chybu.
 * 
 * @param   {string}  message [mandatory] Popis chyby
 * @return  {applicationErrorBody}
 */
export function createApplicationError (message) {
  let error = buildApplicationError(message)

  processError(error)

  return error
}

/**
 * Vytvoří a zpracuje fatální chybu.
 * 
 * @param   {string}  message [mandatory] Popis chyby
 * @return  {applicationErrorBody}
 */
export function createFatalError (message) {
  let error = buildFatalError(message)

  processError(error)
  
  return error
}

/**
 * Vytvoří a zpracuje poznámku.
 * 
 * @param   {string}  message [mandatory] Text poznámky
 * @return  {noticeBody}
 */
export function createNotice (message) {
  let notice = buildNotice(message)

  processError(notice)

  return notice
}

/**
 * Vytvoří a zpracuje varování.
 * 
 * @param   {string}  message [mandatory] Text varování
 * @return  {warningBody}
 */
export function createWarning (message) {
  let warning = buildWarning(message)

  processError(warning)

  return warning
}

/**
 * Zpracuje chybu.
 * 
 * @param {object}  errorBody [mandatory] Tělo chyby
 * @example   error:
 *  {
 *    "type":"ApplicationError",
 *    "priority":"Error",
 *    "message":"Neplatné uživatelské jméno nebo heslo"
 *  }
 */
export function processError (error) {
  //console.log('error.js#processError( ).error', error)

  let eventType = null
  if (error.priority == ERROR_PRIORITY[ERROR_PRIORITY_URGENT]) eventType = eventBus.eventTypes.URGENT_ERROR
  if (error.priority == ERROR_PRIORITY[ERROR_PRIORITY_ERROR]) eventType = eventBus.eventTypes.ERROR
  if (error.priority == ERROR_PRIORITY[ERROR_PRIORITY_WARNING]) eventType = eventBus.eventTypes.WARNING
  if (error.priority == ERROR_PRIORITY[ERROR_PRIORITY_NOTICE]) eventType = eventBus.eventTypes.NOTICE
  
  //console.log(eventType)
  //console.log(error)

  eventBus.dispatch(
    eventType,
    error
  )
}

/**
 * Zpracuje urgentní chybu.
 * 
 * @param {object}  errorBody [mandatory] Tělo chyby
 * @example   error:
 *  {
 *    "type":"FatalError",
 *    "priority":"Urgent",
 *    "message":"timeout of 3000ms exceeded"
 *  }
 */
export function processUrgentError (/*error*/) {
  //console.log('error.js#processUrgentError( ).error', error)

  /*localStorage.removeItem('access_token')
  delete axios.defaults.headers.common['Authorization']
  localStorage.removeItem('userData')*/
}

/**
 * Zpracuje chyby.
 * 
 * @param     {array}   errors                    [mandatory] Pole chyb
 * @param     {object}  [errors].errorBody        [mandatory] Tělo chyby
 * @example   errors:
 *  [
 *    {
 *       "type":"ApplicationError",
 *       "priority":"Error",
 *       "message":"Neplatné uživatelské jméno nebo heslo"
 *    }
 *  ]
 */
export function processErrors (errors) {
  //console.log('processError.errors', errors)
  
  errors.forEach((error) => {
    processError(error)
  })
  
}

/**
 * Zpracuje chyby v Vue komponentě.
 * V současné chvíli jsou veškeré chyby zpracovány přes EventBus.
 * Přesto veškeré BL metody publikují chyby a je nutné je zpracovat v catch(errors).
 * 
 * @param     {array}   errors                    [mandatory] Pole chyb
 * @param     {object}  [errors].errorBody        [mandatory] Tělo chyby
 * @example   errors:
 *  [
 *    {
 *       "type": typ chyby,
 *       "priority": priorita chyby,
 *       "message": ...
 *    }
 *  ]
 */
export function processViewErrors (/*errors*/) {
  //console.log('error.js#processViewErrors(errors)', errors)
}

//////////////////////////////////////////////////////////////////////////////////////////////
  /**
   * Handles an success
   * 
   * @param     {*}       parameters
   * @property  {object}  [parameters].success            [optional] Success body
   * @property  {object}  [parameters].[success].data     [optional] Success data
   * @property  {string}  [parameters].[success].level    [optional] Level (importance) of success
   * @property  {string}  [parameters].[success].message  [optional] Success message
   * @property  {string}  [parameters].[success].type     [optional] Type (source) of success
   * 
   */
  /*processSuccess: (parameters) => {
    //console.log('processStockApiError.parameters', parameters)
    
    let parametersDefault = {   
        data: null,
        level: stock_api.errorLevels.SUCCESS,
        message: 'All right.',
        type: event_bus.eventTypes.STOCK_API_SUCCESS
    }

    let parametersFinal = Object.assign(parametersDefault, parameters)

    //console.log('processStockApiError.paramatersFinal', paramatersFinal)

    event_bus.dispatch(
      parametersFinal.type,
      parametersFinal
    )
  }*/