<template>
    <div>
    </div>
</template>

<script>
/**
 * This file is part of the GMS package.
 * 
 * @version 1.2
 */

/**
 * Obsluha store.
 * 
 * NOTICE
 * Může řídit globální události nad stavem.
 * Další možnost je definovat property objektu, viz.:
 *  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty
 *  https://stackoverflow.com/questions/50020982/how-to-subscribe-to-object-changes
 */
import storage from "@/lib/gms/storage"
import * as constants from '@/store/constants.js'
import { state, mutations } from '@/store'
import { defaultsAuth } from '@/store/auth.js'

export default {
    computed: {
        data_state: function ( ) {
            return state.data_state
        },

        /*data_state_config( ) {
            return state.data_state.config.state
        },*/

        state: function ( ) {
            return state
        },

        mutations: function ( ) {
            return mutations
        }
    },

    mounted( ) {
        // Načtení konfigurace frontend aplikace
        mutations.readSetting( )

        // Obnovení dat frontend aplikace (state) ze storage
        let auth = storage.getItem("auth")
        if (auth != null) {
            state.auth.access_token = auth.access_token
            state.auth.login_user = auth.login_user
            state.database_id = auth.database_id
        } else {
            state.auth.access_token = defaultsAuth.access_token
            state.auth.login_user = defaultsAuth.login_user
            state.database_id = null
        }
    },

    /**
     * SEE https://stackoverflow.com/questions/42737034/vue-js-watch-multiple-properties-with-single-handler
     */
    watch: {
        /* //EXAMPLE Ukázka reakce na změnu stavu (událost)
        data_state_parts: function (newState, oldState) {
            console.log(oldState + ' --> ' + newState)
        }*/

        /*data_prepared: function (newState, oldState) {
            console.log(oldState + ' --> ' + newState)

            if (newState == defaults.DATA_STATE_READY) {
                mutations.readPackages( )
                mutations.readPlate( )
                mutations.readBOM( )
            }
        },*/

        data_state_config: function (newState/*, oldState*/) {
            //console.log(oldState + ' --> ' + newState)

            if (newState == constants.DATA_STATE_READY) {
                // ...
            }
        }
    }
}
</script>