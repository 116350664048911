<template>
  <div>
  </div>
</template>

<script>
/**
 * This file is part of the GMS package.
 * 
 * @version 1.2
 */

import { eventBus } from '@/lib/gms/event_bus.js'
import { /*state,*/ mutations } from '@/store/index.js'

/**
 *  Globální EventBus.
 */
export default {
  methods: {
    onError: function (error) {
      console.log('EventBus.vue#onError', error)

      mutations.processError(this, error)
    },

    onNotice: function (error) {
      console.log('EventBus.vue#onNotice', error)

      mutations.processNotice(this, error)
    },

    onUrgentError: function (error) {
      console.error('EventBus.vue#onUrgentError', error)

      mutations.processUrgentError(this, error)
    },

    onWarning: function (error) {
      console.log('EventBus.vue#onWarning', error)

      mutations.processWarning(this, error)
    }
  },

  mixins: [ ],

  mounted( ) {
    // Událost "ERROR"
    eventBus.addEventListener(eventBus.eventTypes.ERROR, parameters => {
      this.onError(parameters)
    })

    // Událost "NOTICE"
    eventBus.addEventListener(eventBus.eventTypes.NOTICE, parameters => {
      this.onNotice(parameters)
    })

    // Událost "URGENT_ERROR"
    eventBus.addEventListener(eventBus.eventTypes.URGENT_ERROR, parameters => {
      this.onUrgentError(parameters)
    })

    // Událost "WARNING"
    eventBus.addEventListener(eventBus.eventTypes.WARNING, parameters => {
      this.onWarning(parameters)
    })
  }
}
</script>