// Vue
import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// CSS libs
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/style.scss'

// JS libs
import 'bootstrap'
import 'jquery'
import numeral from 'numeral'
import 'popper.js'
import router from './router'
import i18n from './i18n'
//import '@fortawesome/fontawesome-free/js/all' // QUESTION Možná odkomentovat?

// 3rd party plugins
import '@/lib/acl'

// Numeral
// Přepnutí locales do "cs"
numeral.register('locale', 'cs', {
  delimiters: {
      thousands: ' ',
      decimal: ','
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  /*ordinal : function (number) {
      return number === 1 ? 'er' : 'ème';
  },*/
  currency: {
      symbol: 'Kč'
  }
})

numeral.locale('cs')

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
