<template>
    <div>
        <event-bus/>
        <store />
    </div>
</template>

<script>
/**
 * Zastřešuje všechny Vue komponenty balíčku GMS.
 */

import EventBus from './EventBus.vue'
import Store from './Store.vue'

export default {
  components: {
    EventBus,
    Store
  },
  
  mounted( ) { }
}
</script>