import * as constants from '@/store/constants.js'
import { /*mutations,*/ state } from '@/store'

//export const defaultsDataState = { }

export const stateDataState = {
    // Aktuální stav dat
    data_state: {
        administration: {
            student: {
                state: constants.DATA_STATE_NOT_READY
            },

            student_training: {
                state: constants.DATA_STATE_NOT_READY
            },

            student_trainings: {
                state: constants.DATA_STATE_NOT_READY
            },

            students: {
                state: constants.DATA_STATE_NOT_READY
            },

            test: {
                state: constants.DATA_STATE_NOT_READY
            },

            test_question: {
                state: constants.DATA_STATE_NOT_READY
            },

            test_questions: {
                state: constants.DATA_STATE_NOT_READY
            },

            training: {
                state: constants.DATA_STATE_NOT_READY
            },

            training_legislation: {
                state: constants.DATA_STATE_NOT_READY
            },

            training_legislations: {
                state: constants.DATA_STATE_NOT_READY
            },

            trainings: {
                state: constants.DATA_STATE_NOT_READY
            }
        },

        config: {
            state: constants.DATA_STATE_NOT_READY
        },

        training: {
            test: {
                state: constants.DATA_STATE_NOT_READY
            },

            /*
            test_question: {
                state: constants.DATA_STATE_NOT_READY
            },

            test_questions: {
                state: constants.DATA_STATE_NOT_READY
            },*/

            training: {
                state: constants.DATA_STATE_NOT_READY
            },

            training_legislations: {
                state: constants.DATA_STATE_NOT_READY
            },

            training_summary: {
                state: constants.DATA_STATE_NOT_READY
            },

            trainings: {
                state: constants.DATA_STATE_NOT_READY
            }
        },

        /*training: {
            state: constants.DATA_STATE_NOT_READY
        },

        trainings: {
            state: constants.DATA_STATE_NOT_READY
        },*/

        users: {
            state: constants.DATA_STATE_NOT_READY
        }
    }
}

export const mutationsDataState = {
    /*setDataStateAdministrationStudent(newState) {
        state.data_state.administration.student.state = newState
    },

    setDataStateAdministrationStudentTraining(newState) {
        state.data_state.administration.student_training.state = newState
    },

    setDataStateAdministrationStudentTrainings(newState) {
        state.data_state.administration.student_trainings.state = newState
    },

    setDataStateAdministrationStudents(newState) {
        state.data_state.administration.students.state = newState
    },

    setDataStateAdministrationTest(newState) {
        state.data_state.administration.test.state = newState
    },

    setDataStateAdministrationTestQuestion(newState) {
        state.data_state.administration.test_question.state = newState
    },

    setDataStateAdministrationTestQuestions(newState) {
        state.data_state.administration.test_questions.state = newState
    },

    setDataStateAdministrationTraining(newState) {
        state.data_state.administration.training.state = newState
    },

    setDataStateAdministrationTrainings(newState) {
        state.data_state.administration.trainings.state = newState
    },

    setDataStateAdministrationTrainingLegislation(newState) {
        state.data_state.administration.training_legislation.state = newState
    },

    setDataStateAdministrationTrainingLegislations(newState) {
        state.data_state.administration.training_legislations.state = newState
    },*/

    setDataStateConfig(newState) {
        state.data_state.config.state = newState
    },

    /*setDataStateTrainingSummary(newState) {
        state.data_state.training.training_summary.state = newState
    },

    setDataStateTrainingTest(newState) {
        state.data_state.training.test.state = newState
    },

    setDataStateTrainingTraining(newState) {
        state.data_state.training.training.state = newState
    },

    setDataStateTrainingTrainings(newState) {
        state.data_state.training.trainings.state = newState
    },*/

    setDataStateUsers(newState) {
        state.data_state.users.state = newState
    }
}