const axios = require("axios")
//import * as constants from "@/store/constants.js"
import { defaultsAuth } from "./auth"
import { /*mutations,*/ state } from "@/store"
import { sendRequest } from "@/lib/gms/api"
import storage from "@/lib/gms/storage"
import { deepCopy } from "@/lib/gms/utils"
import ability from "@/lib/acl/ability"
import { createFatalError } from '@/lib/gms/error'

export const defaultsReport = {
    /**
     * Parametry reportu.
     * 
     *  @var {object}
     * 
     */
    report_params: { },

    /**
     * Data reportu.
     * 
     *  @var {object}
     * 
        [
            {
                ...
            }, ...
        ]
     * 
     */
    report_result: [ ],

    /**
     * Nastavení reportu.
     * 
     *  @var {object}
     */
    report_setting: {
        format: {
            fields: [ ]
        }
    },

    /**
     * Data reportů.
     * 
     *  @var {object}
     * 
        [
            {
                ...
            }, ...
        ]
     */
    reports_data: [ ]
}

export const stateReport = {
    /**
     * Id databáze.
     * 
     *  @var {string}
     * 
     */
    database_id: null,

    /**
     * Id reportu.
     * 
     *  @var {string}
     * 
     */
    report_id: null,

    /**
     * Parametry reportu.
     * 
     *  @var {object}
     * 
     */
    report_params: defaultsReport.report_params,

    /**
     * Data reportu.
     * 
     *  @var {object}
     * 
        [
            {
                ...
            }, ...
        ]
     * 
     */
    report_result: defaultsReport.report_result,

    /**
     * Nastavení reportu.
     * 
     *  @var {object}
     */
    report_setting: defaultsReport.report_setting,

    /**
     * Data reportů.
     * 
     *  @var {object}
     * 
        [
            {
                ...
            }, ...
        ]
     */
    reports_data: defaultsReport.reports_data
}

export const mutationsReport = {
    /**
     * Vytvoří chybu pro chybějící vstupní parametr "db".
     * 
     */
    createInvalidDbParameterError( ) {
        createFatalError(
            "Neplatný parametr 'db' v URL.<br>"
            + "Zkuste znovu zadat správné (" + location.origin + "?db= název databáze) URL do prohlížeče.<br>"
            + "Zároveň nedělejte refresh obrazovky.<br>Případně kontaktujte podporu."
        )
    },

    /**
     * Provede autentizaci a autorizaci uživatele.
     * 
     * @param   {string}    database_id
     * @param   {string}    username
     * @param   {string}    password
     * 
     * @return  {Promise}   
     */
    loginToReports(database_id, username, password) {
        return new Promise((resolve, reject) => {
            //mutations.setDataStateHost(constants.DATA_STATE_PREPARING)

            state.auth.login_form_errors = deepCopy(defaultsAuth.login_form_errors)

            sendRequest(
                "/api/reports/login",
                {
                    database_id,
                    username,
                    password
                    
                }
            )
            .then(response => {
                const accessToken = response.data.access_token
                const databaseId = response.data.database_id
                //const key = response.data.key
                const userAbility = response.data.ability
                const userData = response.data.user
                //const reportResult = response.data.report.result
                //const reportSetting = response.data.report.setting
                
                // Uložení aktuálního připojení k DB
                const auth = {
                    "ability": userAbility,
                    "access_token": accessToken,
                    "database_id": databaseId,
                    //"key": key,
                    "login_user": userData
                }

                storage.setItem(
                    "auth",
                    auth
                )

                // Uložení tohoto připojení k DB do seznamu dostupných připojení
                const authAvailable = storage.getItem("auth_available") ?? { }

                authAvailable[databaseId] = {
                    "ability": userAbility,
                    "access_token": accessToken,
                    "database_id": databaseId,
                    //"key": key,
                    "login_user": userData
                }

                storage.setItem(
                    "auth_available",
                    authAvailable
                )

                state.auth.ability = userAbility
                ability.update(userAbility)

                state.auth.access_token = accessToken
                axios.defaults.headers.common["Authorization"] = accessToken

                state.auth.login_user = userData

                //state.key = key

                resolve(response.data)
            })
            .catch(errors => {
                //mutations.setDataStateHost(constants.DATA_STATE_NOT_READY)

                reject(errors)
            })
        })
    },

    logoutFromReports(/*params*/) {
        return new Promise((resolve/*, reject*/) => {
            let databaseId = state.database_id

            // Odstranění aktuálního připojení k DB
            storage.removeItem("auth")
            
            // Odstranění tohoto připojení k DB ze seznamu dostupných připojení
            let authAvailable = storage.getItem("auth_available")

            if (authAvailable && authAvailable[databaseId]) {
                delete authAvailable[databaseId]
            }

            storage.setItem(
                "auth_available",
                authAvailable
            )

            //storage.removeItem("auth")

            state.auth.ability = defaultsAuth.ability
            ability.update(defaultsAuth.ability)

            state.auth.access_token = defaultsAuth.access_token
            delete axios.defaults.headers.common["Authorization"]

            state.auth.login_user = deepCopy(defaultsAuth.login_user)
            state.auth.login_form = deepCopy(defaultsAuth.login_form)
            state.auth.login_form_errors = deepCopy(defaultsAuth.login_form_errors)

            resolve( )
        })
    },

    /**
     * Provede autentizaci a autorizaci uživatele a následné spuští report.
     * Pořadí auth:
     *  1. Pokud "key" != null, Auth podle "database_id" a "key"
     *  2. Pokud "key" == null, Auth podle "database_id", "username" a "password"
     * 
     * @param   {string}    database_id
     * @param   {string}    key
     * @param   {string}    username
     * @param   {string}    password
     * @param   {string}    report_id
     * @param   {object}    report_params
     * 
     * @return  {Promise}   
     */
    processReport(database_id, key, username, password, report_id, report_params) {
        

        //console.log("loginForReport( )", arguments)

        return new Promise((resolve, reject) => {
            state.auth.login_form_errors = deepCopy(defaultsReport.login_form_errors)

            sendRequest(
                "/api/report/process",
                {
                    database_id,
                    key,
                    username,
                    password,
                    report_id,
                    report_params
                }
            )
            .then(response => {
                //const userAbility = response.data.ability
                //const accessToken = response.data.access_token
                const key = response.data.auth.key
                const userData = response.data.auth.user
                const reportResult = response.data.report.result
                const reportSetting = response.data.report.setting

                storage.setItem(
                    "auth", 
                    {
                        //"ability": userAbility,
                        //"access_token": accessToken,
                        "key": key,
                        "login_user": userData
                    }

                )

                //state.auth.ability = userAbility
                //ability.update(userAbility)

                //state.auth.access_token = accessToken
                //axios.defaults.headers.common["Authorization"] = accessToken

                state.auth.login_user = userData

                state.key = key

                state.report_result = reportResult

                state.report_setting = reportSetting

                //console.log(state.report_setting)

                resolve(response.data)
            })
            .catch(errors => {
                reject(errors)
            })
        })
    },

    /**
     * Načte data reportu.
     * 
     * @return {Promise}
     */
    readReport(reportId) {
        return new Promise((resolve, reject) => {
            //mutations.setDataStateUsers(constants.DATA_STATE_PREPARING)

            sendRequest(
                'api/report/read-report',
                {
                    //database_id: databaseId,
                    //key: key
                    report_id: reportId
                }
            )
            .then(response => {
                const reportResult = response.data.result
                const reportSetting = response.data.setting
    
                state.report_result = reportResult

                state.report_setting = reportSetting
                
                resolve(response.data)
            })
            .catch(errors => {
                reject(errors)
            })
        })
    },

    /**
     * Načte data reportů.
     * 
     * @return {Promise}
     */
    readReports(/*databaseId, key*/) {
        return new Promise((resolve, reject) => {
            //mutations.setDataStateUsers(constants.DATA_STATE_PREPARING)

            sendRequest(
                'api/report/read-reports',
                {
                    //database_id: databaseId,
                    //key: key
                }
            )
            .then(response => {
                //console.log(response)
    
                state.reports_data = response.data
                
                //mutations.setDataStateUsers(constants.DATA_STATE_READY)

                resolve(response.data)
            })
            .catch(errors => {
                //console.log('readPlate.errors', errors)

                //mutations.setDataStateUsers(constants.DATA_STATE_NOT_READY)

                reject(errors)
            })
        })
    },

    setDatabaseId(databaseId) {
        state.database_id = databaseId
    },

    setReportId(reportId) {
        state.report_id = reportId
    },

    setReportParams(params) {
        state.report_params = params
    },

    setReportResult(result) {
        state.report_result = result
    },

    setReportSetting(setting) {
        state.report_setting = setting
    },

    setReportsData(data) {
        state.reports_data = data
    }
}