export default [
    {
        name: 'default-view',
        path: '/',
        component: ( ) => import('@/views/default-view/DefaultView.vue'),
        meta: {
            //redirect_if_logged_in: true
        }
    }/*,

    {
        name: 'default-view-anonymous-user',
        path: '/',
        component: ( ) => import('@/views/default-view/DefaultViewAnonymousUser.vue'),
        meta: {
            //redirect_if_logged_in: true
        }
    },

    {
        name: 'default-view-logged-in-user',
        path: '/home',
        component: ( ) => import('@/views/default-view/DefaultViewLoggedInUser.vue'),
        meta: {
            subject: 'Auth',
            action: 'read',
            //redirect_if_logged_in: true
        }
    }*/
]